.social-menu {
	.menu {
    position: relative;
    font-size: 0;
		li {
			display     : block;
      float       : left;
      margin      : 0;
      position    : relative;
			width       : auto !important;
      @extend %transit;
      a {
        font-size : 0;
      }
      //&:before {
      //  display: none;
      //}

		}


	}
}

.menu {
  li {
    a {
      &:before {
        display   : inline-block;
        font-size : 20px;
        @extend %icon;
      }
      &[href*="facebook"]:before {
        content : "\f09a";
      }
      &[href*="twitter"]:before {
        content : "\f099";
      }
      &[href*="instagram"]:before {
        content : "\f16d";
      }
      &[href*="youtube"]:before {
        content : "\f16a";
      }
      &[href*="plus.google.com"]:before {
        content : "\f0d5";
      }
      &[href*="flickr"]:before {
        content : "\f16e";
      }
      &[href*="dribbble"]:before {
        content : "\f17d";
      }
      &[href*="foursquare"]:before {
        content : "\f180";
      }
      &[href*="linkedin"]:before {
        content : "\f0e1";
      }
      &[href*="tumblr"]:before {
        content : "\f173";
      }
      &[href*="feed"]:before {
        content : "\f09e";
      }
    }
  }
}

//Social menu - right arrow
.social-menu_right-arrow {
  .menu {
    &:before {
      content     : '';
      width       : 0;
      height      : 0;
      border-style: solid;
      border-width: 25px 15px 25px 0;
      position    : absolute;
      top         : 0;
      right       : 0;
      z-index     : 2;
      @extend %transit;
      border-color: rgba(0, 0, 0, 0) $color-1 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    }
  }
  li {
    padding: 15px 30px 15px 15px;
    background: $color-1;
    &:hover {
      background: #111;
      &::after {
        border-color: rgba(0, 0, 0, 0) #111 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      }
      a {
        color: #fff;
      }
    }
    a {
      color: #111;
    }
    &:before {
      @include triangle-rn;
      border-right-color: rgba(255,255,255,0.2);
      border-width: 25px 15px 25px 0;
      top: 0;
      right: 1px;
    }
    &:after {
      content     : '';
      width       : 0;
      height      : 0;
      border-style: solid;
      border-width: 25px 15px 25px 0;
      position    : absolute;
      top         : 0;
      right       : 100%;
      z-index     : 1;
      border-color: rgba(0, 0, 0, 0) $color-1 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      @extend %transit;
    }
  }
}
