// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size : ($sizeValue * 16) * 1px;
	font-size : $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display      : block;
	margin-left  : auto;
	margin-right : auto;
}

// Font Awesome
@mixin font-awesome {
	font-family    : 'FontAwesome';
	font-style     : normal;
	font-weight    : normal;
	font-variant   : normal;
	text-transform : none;
	line-height    : 1;
}

// Transition
@mixin transit {
	@include transition(all 0.4s $ease-in-out-cubic);
}

// Center
@mixin is-translated($width: 100%) {
	width             : $width;
	margin            : auto;
	position          : absolute;
	top               : 50%;
	left              : 50%;
	-webkit-transform : translate(-50%, -50%);
	transform         : translate(-50%, -50%);
}

// Translate
@mixin translate($x,$y) {
  -ms-transform: translate($x,$y); /* IE 9 */
  -webkit-transform: translate($x,$y); /* Safari */
  transform: translate($x,$y); /* Standard syntax */
}

// Translate y center
@mixin translate-y-center () {
  position          : absolute;
  top               : 50%;
  -webkit-transform : translate(0, -50%);
  transform         : translate(0, -50%);
}

// Absolute center
@mixin absolute-center() {
	margin   : auto;
	position : absolute;
	top      : 0;
	left     : 0;
	bottom   : 0;
	right    : 0;
}

// Scale
@mixin scale($ratio:1.5) {
  -webkit-transform: scale($ratio);
  -moz-transform: scale($ratio);
  -o-transform: scale($ratio);
  -ms-transform: scale($ratio);
  transform: scale($ratio);
}

//Skew
@mixin skew($x,$y) {
  webkit-transform: skew($x,$y);
  -moz-transform: skew($x,$y);
  -ms-transform: skew($x,$y);
  -o-transform: skew($x,$y);
  transform: skew($x,$y);
}

//Rotate
@mixin rotate($rotate) {
  webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  transform: rotate($rotate);
}

//Triangle
@mixin triangle-rn() {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  top: 0;
  z-index: 1;
}
