#scrollUp {
  bottom: 50px;
  right: 20px;
  width: 40px;
  font-size: 30px;
  line-height: 38px;
  height: 40px;
  text-align: center;
  background: $color-1;
  color: #fff;
  &:hover {
    font-size: 35px;
    line-height: 35px;
  }
}
