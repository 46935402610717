/*------------------------------------------------------------------
Theme Name: Renovation
Version: 1.0
Author:
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]

## 01. GLOBAL STYLES
   01-01. Normalize
   01-02. Typography
   01-03. List

## 02. COMBO CSS

## 03. NAVIGATION
   03-01. Menus
   03-01. Mobile menu
   03-01. Social menu

## 04. LAYOUT
   04-01. Header
   04-02. Footer
   04-03. Widget, sidebar
   04-04. Mini cart

## 05. OBJECTS
   05-01. Button
   05-02. Collapse
   05-03. Slider
   05-04. Breadcrumb
   05-05. Custom heading
   05-06. Pagination
   05-07. Scrollup
   05-08. Forms

## 06. COMPONENTS
   06-01. Features
   06-02. We are
   06-03. Services
   06-04. Our works
   06-05. Testimonial
   06-06. Contact form
   06-07. Brands
   06-08. New tips
   06-09. Choose us

## 07. CONTENT
   07-01. Page extra
   07-02. Page extra
   07-03. Blog
   07-04. Shop
   07-05. About
   07-06. Page other

-------------------------------------------------------------------*/
/*--------------------------------------------------------------
01. GLOBAL STYLES
--------------------------------------------------------------*/
/* ---------------------
  01-01. Normalize
--------------------- */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-size: 1em;
  font-family: monospace, monospace;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

legend {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

a {
  text-decoration: none;
  color: #111;
}

a:hover {
  color: #FBD232;
}

button {
  outline: none;
}

/* ---------------------
  01-02. Typography
--------------------- */
body {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat";
  font-weight: 700;
  letter-spacing: 0em;
}

p {
  color: #666;
}

blockquote {
  border: 2px solid #FBD232;
  font-size: 18px;
  padding: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin: 10px 0px 20px;
}

/* ---------------------
  01-03. List
--------------------- */
ul, ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

/*--------------------------------------------------------------
02. COMBO CSS
--------------------------------------------------------------*/
.sidebar .product-list-widget li::after, .mini-cart .mini-cart__content .cart-list li::after, .page-service-detail-02 .service-list_item-categories::after, .call-us_phone::after, .call-us_address::after {
  content: "";
  display: table;
  clear: both;
}

.menu li a:before, .mini-cart .mini-cart__button .mini-cart-icon:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

a, .sub-menu, .primary-menu_style-01 .menu > li:hover > a:after, .primary-menu_style-01 .menu > li.active > a:after, .primary-menu_style-02 .menu > .menu-item:hover > a, .primary-menu_style-03 .menu > .menu-item > a:before, .social-menu .menu li, .social-menu_right-arrow .menu:before, .social-menu_right-arrow li:after, .mini-cart .mini-cart__button, .mini-cart .mini-cart__content, .accordion-rn .panel-heading .panel-title a:after, .pagination li a:before, .pagination li a:after, select,
textarea,
input, button,
input[type="button"],
input[type="reset"],
input[type="submit"], .service-item_img img, .service-item_icon, .service-item_icon:before, .service-item_icon:after, .filters-button-group .button:before, .filters-button-group .button:after, .owl-dot, .brand-item-element, .brand-item-overlay, .product-thumb_link:before {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/*--------------------------------------------------------------
03. NAVIGATION
--------------------------------------------------------------*/
/*-----------------------------
03-01. Menus
-----------------------------*/
.menu {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  font-size: 0;
}

.menu a {
  display: block;
  font-size: 14px;
}

.menu a:hover {
  color: red;
}

.sub-menu {
  position: absolute;
  top: 120%;
  left: 0;
  z-index: 9999;
  padding: 0;
  margin-left: 0;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.sub-menu .sub-menu {
  left: 110%;
  top: 0;
}

.sub-menu li:hover > .sub-menu {
  left: 100%;
  top: -3px;
}

.sub-menu .menu-item {
  display: block;
}

.menu-item {
  position: relative;
  display: inline-block;
}

.menu-item:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
}

/* ----- PRIMARY MENU ----- */
#primary-menu {
  position: relative;
}

#primary-menu .menu {
  position: relative;
}

#primary-menu .menu a {
  text-transform: uppercase;
  font-weight: 600;
  font-family: Montserrat;
}

#primary-menu .menu .menu-item-has-children > a:after {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\f105";
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 17px;
}

#primary-menu .menu .mega-menu {
  position: static;
}

#primary-menu .menu .mega-menu > ul {
  width: 100%;
  top: 100% !important;
  font-size: 15px;
}

#primary-menu .menu .mega-menu .sub-menu > li:hover {
  background: transparent;
}

#primary-menu .menu .sub-menu {
  border-top: 3px solid #FBD232;
  background: #fff;
  width: 300px;
}

#primary-menu .menu .sub-menu li {
  padding: 15px 20px 17px 20px;
}

#primary-menu .menu .sub-menu li:hover {
  background: #FBD232;
}

#primary-menu .menu .sub-menu li:hover a {
  color: #111;
}

#primary-menu .menu .sub-menu li a {
  text-transform: capitalize;
  font-weight: 400;
  font-family: Lato;
}

.primary-menu_style-01 .menu a {
  color: #666666;
}

.primary-menu_style-01 .menu > li {
  border-bottom: 4px solid transparent;
}

.primary-menu_style-01 .menu > li:hover, .primary-menu_style-01 .menu > li.active {
  border-color: #FBD232;
}

.primary-menu_style-01 .menu > li:hover > a, .primary-menu_style-01 .menu > li.active > a {
  color: #111;
}

.primary-menu_style-01 .menu > li:hover > a:after, .primary-menu_style-01 .menu > li.active > a:after {
  opacity: 1;
  visibility: visible;
  bottom: 0;
  margin-left: -8px;
}

.primary-menu_style-01 .menu > li:hover > .sub-menu, .primary-menu_style-01 .menu > li.active > .sub-menu {
  top: calc(100% + 4px);
}

.primary-menu_style-01 .menu > li > a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  top: 0;
  z-index: 1;
  border-width: 0 8px 3px 8px;
  border-bottom-color: #FBD232;
  position: absolute;
  left: 50%;
  top: auto;
  bottom: -10px;
  opacity: 0;
  visibility: hidden;
}

.primary-menu_style-01 .menu li {
  padding: 27px 20px;
  margin-left: 0;
}

.primary-menu_style-02 {
  background: #FBD232;
  position: relative;
  margin-bottom: -36px;
}

.primary-menu_style-02 .menu {
  position: relative;
  z-index: 999;
}

.primary-menu_style-02 .menu:before {
  content: '';
  position: absolute;
  width: 1000px;
  height: 100%;
  top: 0;
  left: -1000px;
  background: #FBD232;
}

.primary-menu_style-02 .menu:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  top: 0;
  z-index: 1;
  z-index: 3;
  border-left-color: #FBD232;
  border-width: 35px 0 35px 20px;
  left: 0px;
}

.primary-menu_style-02 .menu > .menu-item {
  padding: 25px 10px 25px 40px;
  background: #FBD232;
}

.primary-menu_style-02 .menu > .menu-item:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  top: 0;
  z-index: 1;
  z-index: 4;
  border-left-color: #FBD232;
  border-width: 35px 0 35px 20px;
  right: -20px;
}

.primary-menu_style-02 .menu > .menu-item:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  top: 0;
  z-index: 1;
  z-index: 3;
  border-left-color: rgba(255, 255, 255, 0.2);
  border-width: 35px 0 35px 20px;
  right: -21px;
}

.primary-menu_style-02 .menu > .menu-item:hover {
  background: #111;
}

.primary-menu_style-02 .menu > .menu-item:hover > a {
  color: #fff;
}

.primary-menu_style-02 .menu > .menu-item:hover:before {
  border-left-color: #111;
}

.primary-menu_style-02 .menu > .menu-item:hover:after {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.primary-menu_style-02 .menu .mega-menu {
  position: relative !important;
}

.primary-menu_style-02 .menu .mega-menu .sub-menu {
  width: 1140px !important;
  max-width: 1140px;
  left: -95px;
}

.primary-menu_style-03 .menu > .menu-item > a {
  padding: 54px 0px;
  position: relative;
}

.primary-menu_style-03 .menu > .menu-item > a:before {
  content: '';
  width: 0;
  height: 4px;
  background: #FBD232;
  position: absolute;
  top: 0;
  left: 0;
}

.primary-menu_style-03 .menu > .menu-item.menu-item-has-children > a:after {
  content: '\f0d7' !important;
  right: -15px !important;
  top: calc(50% - 8px) !important;
  color: #FBD232;
}

.primary-menu_style-03 .menu > .menu-item.active > a, .primary-menu_style-03 .menu > .menu-item:hover > a {
  color: #111;
}

.primary-menu_style-03 .menu > .menu-item.active > a:before, .primary-menu_style-03 .menu > .menu-item:hover > a:before {
  width: 100%;
}

#top-right-menu a {
  color: rgba(255, 255, 255, 0.6);
}

#top-right-menu a:hover {
  color: #fff;
}

#top-right-menu .sub-menu li {
  display: block;
  padding: 5px 10px;
  margin: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

#top-right-menu .sub-menu .sub-menu {
  top: 0;
  left: 100%;
}

@media (min-width: 48em) {
  #top-right-menu .menu > .menu-item {
    padding: 9px 14px;
    border-left: 1px solid rgba(238, 238, 238, 0.1);
  }
  #top-right-menu .menu > .menu-item:last-child {
    border-right: 1px solid rgba(238, 238, 238, 0.1);
  }
}

@media (min-width: 64em) {
  #top-right-menu .menu > .menu-item {
    padding: 9px 30px;
  }
}

/*-----------------------------
03-02. Mobile menu
-----------------------------*/
#primary-menu-mobile a {
  color: #111;
}

#primary-menu-mobile a:hover {
  color: #FBD232;
}

#primary-menu-mobile .social-menu-mobile {
  font-size: 18px !important;
  line-height: 24px;
}

#primary-menu-mobile .mm-navbar {
  text-align: center;
}

#primary-menu-mobile .mm-navbar-content-4 > .social-menu-mobile {
  width: auto;
  display: inline-block;
  float: none;
  margin: 0px 10px;
}

#primary-menu-mobile .mm-close {
  font-size: 18px;
  font-weight: 700;
}

/*-----------------------------
03-03. Social menu
-----------------------------*/
.social-menu .menu {
  position: relative;
  font-size: 0;
}

.social-menu .menu li {
  display: block;
  float: left;
  margin: 0;
  position: relative;
  width: auto !important;
}

.social-menu .menu li a {
  font-size: 0;
}

.menu li a:before {
  display: inline-block;
  font-size: 20px;
}

.menu li a[href*="facebook"]:before {
  content: "\f09a";
}

.menu li a[href*="twitter"]:before {
  content: "\f099";
}

.menu li a[href*="instagram"]:before {
  content: "\f16d";
}

.menu li a[href*="youtube"]:before {
  content: "\f16a";
}

.menu li a[href*="plus.google.com"]:before {
  content: "\f0d5";
}

.menu li a[href*="flickr"]:before {
  content: "\f16e";
}

.menu li a[href*="dribbble"]:before {
  content: "\f17d";
}

.menu li a[href*="foursquare"]:before {
  content: "\f180";
}

.menu li a[href*="linkedin"]:before {
  content: "\f0e1";
}

.menu li a[href*="tumblr"]:before {
  content: "\f173";
}

.menu li a[href*="feed"]:before {
  content: "\f09e";
}

.social-menu_right-arrow .menu:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 15px 25px 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  border-color: transparent #FBD232 transparent transparent;
}

.social-menu_right-arrow li {
  padding: 15px 30px 15px 15px;
  background: #FBD232;
}

.social-menu_right-arrow li:hover {
  background: #111;
}

.social-menu_right-arrow li:hover::after {
  border-color: transparent #111 transparent transparent;
}

.social-menu_right-arrow li:hover a {
  color: #fff;
}

.social-menu_right-arrow li a {
  color: #111;
}

.social-menu_right-arrow li:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  top: 0;
  z-index: 1;
  border-right-color: rgba(255, 255, 255, 0.2);
  border-width: 25px 15px 25px 0;
  top: 0;
  right: 1px;
}

.social-menu_right-arrow li:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 15px 25px 0;
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 1;
  border-color: transparent #FBD232 transparent transparent;
}

/*--------------------------------------------------------------
04. LAYOUT
--------------------------------------------------------------*/
/*-----------------------------
04-01. Header
-----------------------------*/
.site-branding {
  padding: 20px 15px;
}

@media (min-width: 80em) {
  .site-branding {
    padding: 47px 20px 47px 15px;
  }
}

#open-left {
  font-size: 20px;
  line-height: 1;
  color: #111;
  cursor: pointer;
}

.extra-info i {
  font-size: 32px;
  margin-top: 5px;
  margin-right: 20px;
  float: left;
}

.extra-info h3 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (max-width: 79.9em) {
  .extra-info [class*="col"] {
    margin-bottom: 20px;
  }
}

.search-cart {
  position: relative;
}

.search-cart i {
  font-size: 16px;
}

.social-menu.social-menu_right-arrow {
  position: absolute;
  right: 0;
  bottom: -25px;
  z-index: 1;
  padding-right: 15%;
  background: #FBD232;
}

/* ------- HOME 01 ------- */
.site-top.style-01 {
  background: #222;
}

.site-top.style-01::after {
  content: "";
  display: table;
  clear: both;
}

.site-top.style-01 .site-top-left {
  margin-bottom: 10px;
}

@media (min-width: 48em) {
  .site-top.style-01 .site-top-left {
    margin-bottom: 0px;
  }
}

.site-top.style-01 .site-top-left a {
  color: rgba(255, 255, 255, 0.6);
}

.site-top.style-01 .site-top-left a:hover {
  color: #fff;
}

.site-top.style-01 #top-right-menu .menu .menu-item, .site-top.style-01 #top-right-menu .menu a {
  background: #222;
}

.site-top.style-01 #top-right-menu .menu > .menu-item {
  padding: 10px;
}

@media (min-width: 64em) {
  .site-top.style-01 #top-right-menu .menu > .menu-item {
    padding: 10px 20px;
  }
}

.site-header.style-01 {
  background: #FBD232;
  color: #111;
  position: relative;
}

@media (min-width: 80em) {
  .site-header.style-01 {
    background: #111;
    color: #fff;
  }
  .site-header.style-01 i {
    color: #FBD232;
  }
}

.site-header.style-01 .site-branding {
  background: #FBD232;
  position: relative;
}

@media (min-width: 80em) {
  .site-header.style-01 .site-branding::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1000px;
    width: 1000px;
    background: #FBD232;
  }
  .site-header.style-01 .site-branding::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 65px 0 65px 40px;
    border-color: transparent;
    border-left-color: #FBD232;
    position: absolute;
    top: 0;
    left: 100%;
  }
}

@media (min-width: 80em) {
  .site-header.style-01 .extra-info .col-sm-5 {
    padding-left: 85px;
  }
  .site-header.style-01 .extra-info span {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media (min-width: 80em) {
  .site-header.style-01 .search-cart .search-box {
    margin-bottom: 0;
  }
  .site-header.style-01 .search-cart .mini-cart {
    margin-bottom: 0;
  }
}

/* ------- HOME 02 ------- */
.site-top.style-02 {
  position: relative;
}

.site-top.style-02::after {
  content: "";
  display: table;
  clear: both;
}

.site-top.style-02:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #222;
  z-index: -2;
}

@media (max-width: 63.9em) {
  .site-top.style-02 .site-top-right .menu > li {
    padding: 10px;
  }
}

@media (min-width: 48em) {
  .site-top.style-02 .site-top-right .menu:before {
    content: '';
    position: absolute;
    top: 0;
    right: calc(-100% + 11px);
    width: 100%;
    height: 100%;
    background: #333;
  }
  .site-top.style-02 .site-top-right .menu li {
    background: #333;
  }
  .site-top.style-02 .site-top-right .menu li a {
    background: #333;
  }
  .site-top.style-02 .site-top-right .menu > li:first-child a:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    top: 0;
    z-index: 1;
    border-left-color: #222;
    border-width: 20px 0 20px 11px;
    left: 0px;
  }
  .site-top.style-02 .site-top-right .menu > li {
    border: none !important;
    padding: 9px 10px 10px 30px !important;
  }
  .site-top.style-02 .site-top-right .menu > li:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    top: 0;
    z-index: 1;
    z-index: 2;
    border-left-color: #333;
    border-width: 20px 0 20px 11px;
    right: -11px;
  }
  .site-top.style-02 .site-top-right .menu > li:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    top: 0;
    z-index: 1;
    z-index: 1;
    top: -1px;
    border-left-color: #6d6d6d;
    border-width: 21px 0 21px 12px;
    right: -12px;
  }
}

.site-top.style-02 .social-menu a {
  color: rgba(255, 255, 255, 0.6);
}

.site-top.style-02 .social-menu a:hover {
  color: #fff;
}

.site-top.style-02 .social-menu a:before {
  font-size: 18px;
  margin-right: 15px;
}

.site-header.style-02 .extra-info i {
  position: relative;
  font-size: 18px;
  color: #fff;
  background: #FBD232;
  width: 25px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  margin: 0;
  margin-right: 20px;
}

.site-header.style-02 .extra-info i:after {
  content: '';
  width: 0;
  height: 0;
  z-index: -1;
  border-style: solid;
  border-color: transparent;
  border-width: 21px 0 21px 12px;
  border-left-color: #FBD232;
  position: absolute;
  top: 0;
  left: 100%;
}

.site-header.style-02 .extra-info .phone, .site-header.style-02 .extra-info .address {
  position: relative;
}

.site-header.style-02 .extra-info .phone:before, .site-header.style-02 .extra-info .address:before {
  content: '';
  width: 0;
  height: 0;
  z-index: -1;
  border-style: solid;
  border-color: transparent;
  border-width: 21px 12px 21px 0px;
  border-right-color: #FBD232;
  position: absolute;
  top: 0;
  right: 100%;
}

.site-header.style-02 .search-box, .site-header.style-02 .mini-cart {
  background: #111;
  color: #fff;
  width: 25px;
  height: 42px;
  text-align: center;
  position: relative;
  margin-right: 30px;
}

.site-header.style-02 .search-box:before, .site-header.style-02 .mini-cart:before {
  content: '';
  width: 0;
  height: 0;
  z-index: -1;
  border-style: solid;
  border-color: transparent;
  border-width: 21px 12px 21px 0px;
  border-right-color: #111;
  position: absolute;
  top: 0;
  right: 100%;
}

.site-header.style-02 .search-box:after, .site-header.style-02 .mini-cart:after {
  content: '';
  width: 0;
  height: 0;
  z-index: -1;
  border-style: solid;
  border-color: transparent;
  border-width: 21px 0 21px 12px;
  border-left-color: #111;
  position: absolute;
  top: 0;
  left: 100%;
}

.site-header.style-02 .search-box-icon {
  line-height: 42px;
}

.site-header.style-02 .mini-cart {
  padding: 0;
  margin-right: 0;
}

@media (min-width: 48em) {
  .site-header.style-02 .mini-cart__content {
    right: 0 !important;
  }
}

.site-header.style-02 .mini-cart__button {
  line-height: 42px;
}

.site-header.style-02 .mini-cart__button .mini-cart-icon:after {
  background: #FBD232;
  color: #111;
  font-weight: 500;
}

/* ------- HOME 03 ------- */
.site-top.style-03 {
  color: rgba(255, 255, 255, 0.6);
  background: #333;
  overflow: hidden;
}

.site-top.style-03 .social-menu a {
  margin-left: 20px;
  color: rgba(255, 255, 255, 0.6);
}

.site-top.style-03 .social-menu a:hover {
  color: #fff;
}

.site-top.style-03 .social-menu a:before {
  font-size: 16px;
}

.site-top.style-03 .contact-info {
  font-size: 0;
}

.site-top.style-03 .contact-info li {
  font-size: 15px;
}

.site-top.style-03 .contact-info i {
  color: #FBD232;
  margin-right: 10px;
}

@media (max-width: 47.9em) {
  .site-top.style-03 .contact-info {
    padding-top: 25px;
    padding-bottom: 10px;
  }
  .site-top.style-03 .contact-info li {
    display: block;
    margin-bottom: 15px;
  }
}

@media (min-width: 48em) {
  .site-top.style-03 .contact-info {
    position: relative;
  }
  .site-top.style-03 .contact-info:before {
    content: '';
    position: absolute;
    display: block;
    background: #111;
    top: 0;
    bottom: 0;
    width: 1000px;
    left: -1000px;
  }
  .site-top.style-03 .contact-info li {
    padding: 13px 25px 13px 15px;
    position: relative;
    background: #111;
  }
  .site-top.style-03 .contact-info li:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    top: 0;
    z-index: 1;
    border-width: 24px 0px 24px 20px;
    border-left-color: #111;
    top: 0;
    right: 0;
    z-index: 2;
  }
  .site-top.style-03 .contact-info li:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    top: 0;
    z-index: 1;
    border-width: 24px 0px 24px 20px;
    border-left-color: rgba(255, 255, 255, 0.1);
    top: 0;
    right: -2px;
    z-index: 1;
  }
  .site-top.style-03 .contact-info li:last-child {
    padding-right: 10px;
  }
  .site-top.style-03 .contact-info li:last-child:before {
    right: -20px;
  }
  .site-top.style-03 .contact-info li:last-child:after {
    right: -22px;
  }
}

@media (max-width: 79.9em) {
  .site-header.style-03 {
    background: #FBD232;
  }
}

@media (min-width: 80em) {
  .site-header.style-03 .site-branding {
    background: #FBD232;
  }
  .site-header.style-03 .site-branding:before {
    content: '';
    position: absolute;
    display: block;
    background: #FBD232;
    top: 0;
    bottom: 0;
    width: 1000px;
    left: -1000px;
  }
  .site-header.style-03 .site-branding:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    top: 0;
    z-index: 1;
    border-width: 65px 0px 65px 40px;
    border-left-color: #FBD232;
    left: 100%;
  }
  .site-header.style-03 .mini-cart .mini-cart__button .mini-cart-icon:after {
    background: #FBD232;
    color: #111;
  }
  .site-header.style-03 nav {
    margin-left: 20px;
  }
  .site-header.style-03 nav li {
    padding: 0px 20px;
  }
}

.site-header.style-03 .mini-cart, .site-header.style-03 .search-box {
  margin-bottom: 0;
}

@media (max-width: 47.9em) {
  .site-header.style-03 .mini-cart, .site-header.style-03 .search-box {
    margin-bottom: 20px;
  }
}

.intro {
  background: #111;
  padding: 30px 0px 22px;
  color: #fff;
}

.intro-call {
  padding: 20px 15px;
}

.intro-call i {
  color: #FBD232;
  font-size: 47px;
}

.intro-call h3 {
  font-size: 20px;
}

.intro .extra-info i {
  color: #FBD232;
}

.intro .extra-info span {
  color: #777;
}

@media (min-width: 64em) {
  .intro {
    overflow: hidden;
  }
  .intro-call:before {
    content: '';
    display: block;
    width: 1000px;
    height: 300px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: -100px;
    right: 0;
  }
  .intro-call:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    top: 0;
    z-index: 1;
    border-width: 100px 0px 100px 40px;
    border-color: #111 #111 #111 transparent;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: 0;
  }
  .extra-info [class*="col"] {
    margin-bottom: 0;
  }
}

/*-----------------------------
04-02. Footer
-----------------------------*/
.footer {
  position: relative;
  padding: 70px 0;
  background: url("../images/page-home/footer-bg.jpg");
}

.footer a, .footer p, .footer li, .footer div {
  color: rgba(255, 255, 255, 0.6);
}

.footer:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(17, 17, 17, 0.9);
}

.footer .widget-tittle {
  position: relative;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
  padding-bottom: 20px;
  margin-bottom: 25px;
  overflow: hidden;
}

.footer .widget-tittle:before, .footer .widget-tittle:after {
  content: '';
  height: 2px;
  position: absolute;
  bottom: 0;
  background: #FBD232;
}

.footer .widget-tittle:before {
  width: 20px;
  left: 0;
}

.footer .widget-tittle:after {
  width: 100%;
  left: 30px;
}

.footer-about p {
  margin-bottom: 25px;
  line-height: 2;
}

.footer-infomation li {
  display: inline-block;
  width: 50%;
  margin-right: -3px;
  margin-bottom: 10px;
}

.footer-infomation a:before {
  content: "\f105";
  margin-right: 10px;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.footer-infomation a:hover {
  color: #FBD232;
}

.footer-info li {
  margin-bottom: 10px;
}

.footer-info i {
  font-size: 16px;
  width: 35px;
}

.footer .social-menu {
  left: 0;
  right: auto;
  padding-right: 0;
  padding-left: 15%;
}

.footer .social-menu .menu:before {
  border-width: 25px 0px 25px 15px;
  border-left-color: #FBD232;
  right: auto;
  left: 0;
}

.footer .social-menu .menu li {
  padding: 15px 15px 15px 30px;
}

.footer .social-menu .menu li:before {
  border-width: 25px 0px 25px 15px;
  left: 1px;
  border-left-color: rgba(255, 255, 255, 0.2);
}

.footer .social-menu .menu li:after {
  border-width: 25px 0px 25px 15px;
  border-left-color: #FBD232;
  left: 100%;
  z-index: 2;
}

.footer .social-menu .menu li:hover::after {
  border-left-color: #111;
}

.copyright {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background: #111;
  padding: 50px 0px 45px;
}

.copyright a {
  color: rgba(255, 255, 255, 0.6);
}

.copyright a:hover {
  color: #FBD232;
}

/*-----------------------------
04-03. Widget, sidebar
-----------------------------*/
.sidebar h2 {
  margin-bottom: 30px;
  font-size: 24px;
}

.sidebar .project-categories li {
  margin: 0;
  padding: 0;
  display: block;
}

.sidebar .project-categories li:last-child a {
  border-bottom: 2px solid #ddd;
}

.sidebar .project-categories li a {
  position: relative;
  font-family: Montserrat;
  font-size: 15px;
  color: #999;
  display: block;
  padding: 13px 20px;
  border: 2px solid #ddd;
  border-bottom: none;
}

.sidebar .project-categories li a:hover {
  color: #111;
  background: #FBD232;
  border-color: #FBD232;
}

.sidebar .project-categories li a:after {
  content: '\f061';
  font-family: "FontAwesome";
  font-size: 14px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.sidebar {
  margin-bottom: 50px;
}

.sidebar .widget-title {
  font-size: 22px;
  line-height: 1;
  color: #111;
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

.sidebar .recent-posts_item {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.sidebar .recent-posts .col-sm-3 {
  padding-right: 0;
}

.sidebar .recent-posts img {
  margin-top: 5px;
}

.sidebar .recent-posts_info h3 {
  line-height: 1;
}

.sidebar .recent-posts_info a {
  color: #111;
  font-size: 14px;
  line-height: 1.5em;
}

.sidebar .recent-posts .post-date {
  color: #777;
}

.sidebar .tagcloud a {
  color: #111;
  font-size: 15px;
  border: 2px solid #ddd;
  display: inline-block;
  padding: 5px 12px;
  margin: 0px 2px 8px 0px;
}

.sidebar .tagcloud a:hover {
  background: #FBD232;
  border-color: #FBD232;
}

.widget-search {
  margin-bottom: 30px;
}

.sidebar .products-search {
  position: relative;
}

.sidebar .products-search:after {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: '\f002';
  font-size: 16px;
  position: absolute;
  right: 0px;
  top: 2px;
  text-align: center;
  height: 43px;
  line-height: 41px;
  width: 43px;
  display: block;
  color: #FBD232;
}

.sidebar .products-search input {
  padding: 12px 35px 12px 15px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ddd;
}

.sidebar .products-search input:focus {
  border-color: #FBD232;
}

.sidebar .product-list-widget li {
  margin-bottom: 40px;
}

.sidebar .product-list-widget img {
  width: 80px;
  float: left;
  border: 1px solid #ddd;
  padding: 10px;
  margin-right: 10px;
}

.sidebar .product-list-widget .product-title {
  font-weight: 700;
}

.sidebar .product-categories li {
  margin-bottom: 10px;
}

.sidebar .product-categories a {
  color: #777;
}

.sidebar .product-categories a:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: '\f0ad';
  font-size: 12px;
  display: inline-block;
  margin-right: 10px;
  color: #FBD232;
}

/*-----------------------------
04-04. Mini cart
-----------------------------*/
.mini-cart {
  padding: 0 15px;
  display: inline-block;
  margin-bottom: 20px;
}

.mini-cart .mini-cart__button {
  cursor: pointer;
}

.mini-cart .mini-cart__button .mini-cart-icon {
  position: relative;
}

.mini-cart .mini-cart__button .mini-cart-icon:before {
  float: none;
  margin: 0;
  width: auto;
  height: auto;
  border: none;
  content: '\f07a';
  font-size: 20px;
  line-height: normal;
}

.mini-cart .mini-cart__button .mini-cart-icon:after {
  position: absolute;
  top: -16px;
  right: -12px;
  min-width: 20px;
  content: attr(data-count);
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  font-family: inherit;
  line-height: 20px;
  color: #fff;
  background: #2e2e2e;
  border-radius: 100%;
}

.mini-cart .mini-cart__content {
  position: absolute;
  top: 140%;
  left: 50%;
  margin-left: -150px;
  z-index: 99999;
  visibility: hidden;
  overflow: auto;
  text-align: left;
  padding-top: 15px;
  border-top: 3px solid #FBD232;
  max-height: 700px;
  width: 300px;
  background-color: #fff;
  opacity: 0;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
}

.mini-cart .mini-cart__content .cart-list li {
  margin-bottom: 10px;
  padding: 0 15px 10px;
  border-bottom: 1px solid #eee;
  list-style: none;
}

.mini-cart .mini-cart__content .cart-list li img {
  float: left;
  margin: 0 10px 0 0;
  width: 70px;
}

.mini-cart .mini-cart__content .cart-list li a {
  color: #111;
  font-weight: 700;
}

.mini-cart .mini-cart__content .cart-list li .quantity {
  display: block;
  color: #666;
  font-size: 14px;
}

.mini-cart .mini-cart__content .cart-list li .remove {
  position: relative;
  display: inline-block;
  float: right;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 17px;
}

.mini-cart .mini-cart__content .cart-list li .remove:hover {
  background: #d90000;
  color: #fff;
}

.mini-cart .mini-cart__content .total {
  margin-bottom: 0;
  padding: 5px 15px 5px;
  text-align: center;
  color: #111;
}

.mini-cart .mini-cart__content .buttons {
  margin-bottom: 0;
  text-align: center;
}

.mini-cart .mini-cart__content .btn {
  margin: 10px 3px 15px;
}

.mini-cart .mini-cart__content-open {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.mini-cart .mini-cart__content .amount {
  color: #d90000;
}

@media (min-width: 48em) {
  .mini-cart .mini-cart__content {
    color: #666;
    right: 15px;
    left: auto;
    margin-left: 0;
    text-align: left;
    top: 160%;
  }
  .mini-cart .mini-cart__content-open {
    top: 140%;
  }
}

/*--------------------------------------------------------------
05. OBJECTS
--------------------------------------------------------------*/
/*-----------------------------
05-01. Menus
-----------------------------*/
.btn {
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 700;
  color: #111;
  background: #FBD232;
  padding: 11px 17px;
  display: inline-block;
  border-radius: 0;
}

.btn i {
  margin-left: 5px;
}

.btn:hover {
  color: #fff;
  background: #111;
}

/*-----------------------------
05-02. Collapse
-----------------------------*/
.accordion-rn .panel {
  box-shadow: none;
}

.accordion-rn .panel-heading {
  padding: 0;
}

.accordion-rn .panel-heading .panel-title a {
  background: #FBD232;
  border: 2px solid #FBD232;
  padding: 12px 25px 12px 15px;
  display: block;
  position: relative;
}

.accordion-rn .panel-heading .panel-title a:hover, .accordion-rn .panel-heading .panel-title a:focus {
  color: #111;
}

.accordion-rn .panel-heading .panel-title a:after {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-weight: 700;
  content: "\f106";
  position: absolute;
  top: 12px;
  right: 15px;
}

.accordion-rn .panel-heading .panel-title .collapsed {
  color: #111;
  border-color: #eee;
  background-color: transparent;
}

.accordion-rn .panel-heading .panel-title .collapsed:after {
  webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-rn .panel-body {
  border-top: none;
  border: 2px solid #FBD232;
  border-top: none;
}

#accordion-faq {
  margin: 35px 0px 50px;
}

#accordion-faq .panel-title a:before {
  content: "\f0ad";
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 12px;
  margin-right: 5px;
  color: #111;
}

#accordion-faq .panel-title a.collapsed:before {
  color: #FBD232;
}

#accordion-faq .panel-body {
  color: #777;
  padding: 40px 30px;
}

/*-----------------------------
05-03. Slider
-----------------------------*/
.tp-bullets {
  width: 110px !important;
}

.tp-bullet {
  width: 30px !important;
  height: 5px !important;
  background: rgba(125, 125, 125, 0.5) !important;
}

.custom .tp-bullet:hover, .custom .tp-bullet.selected {
  background: #fff !important;
}

/*-----------------------------
05-04. Breadcrumb
-----------------------------*/
.big-title {
  background: #111;
  margin-bottom: 70px;
}

.big-title h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  padding: 80px 0px;
}

.page-contact-layout-01 .big-title,
.page-projects-fullwidth .big-title,
.page-projects-3columns .big-title,
.page-projects-basic .big-title,
.page-projects-sidebar .big-title {
  margin-bottom: 0;
}

/*-----------------------------
05-05. Custom heading
-----------------------------*/
.heading-title {
  font-size: 32px;
  line-height: 1.2;
  text-transform: capitalize;
  color: #111;
}

.services .heading-title,
.contact-form .heading-title {
  color: #fff;
}

.our-works .heading-title {
  font-size: 36px;
}

.page-home03 .heading-title {
  color: #111;
}

.section-heading {
  margin-bottom: 35px;
}

.page-home03 .services .section-heading {
  margin-bottom: 60px;
}

.section-heading h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

/*-----------------------------
05-06. Pagination
-----------------------------*/
/* --- Pagination --- */
.pagination {
  text-align: center;
  display: block;
  margin: 40px 0px 50px;
}

.pagination ul {
  font-size: 0;
}

.pagination li {
  display: inline-block;
}

.pagination li a {
  position: relative;
  text-align: center;
  display: block;
  width: 25px;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-weight: 700;
  margin: 0px 15px;
  color: #111;
  background: #eee;
}

.pagination li a:before, .pagination li a:after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.pagination li a:before {
  left: -10px;
  border-right-color: #eee;
  border-width: 19px 10px 19px 0px;
}

.pagination li a:after {
  right: -10px;
  border-left-color: #eee;
  border-width: 19px 0px 19px 10px;
}

.pagination li.selected a, .pagination li:hover a {
  color: #fff;
  background: #111;
}

.pagination li.selected a:before, .pagination li:hover a:before {
  border-right-color: #111;
}

.pagination li.selected a:after, .pagination li:hover a:after {
  border-left-color: #111;
}

.pagination li.next a {
  background: #FBD232;
}

.pagination li.next a:before {
  border-right-color: #FBD232;
}

.pagination li.next a:after {
  border-left-color: #FBD232;
}

.pagination li.next:hover a {
  background: #111;
}

.pagination li.next:hover a:before {
  border-right-color: #111;
}

.pagination li.next:hover a:after {
  border-left-color: #111;
}

/*-----------------------------
05-07. Scrollup
-----------------------------*/
#scrollUp {
  bottom: 50px;
  right: 20px;
  width: 40px;
  font-size: 30px;
  line-height: 38px;
  height: 40px;
  text-align: center;
  background: #FBD232;
  color: #fff;
}

#scrollUp:hover {
  font-size: 35px;
  line-height: 35px;
}

/*-----------------------------
05-08. Forms
-----------------------------*/
select,
textarea,
input {
  display: inline-block;
  margin-bottom: 10px;
  padding: 12px 15px;
  max-width: 100%;
  width: 100%;
  border: 2px solid #ddd;
  box-shadow: none;
  color: #888;
  vertical-align: top;
  font-size: 14px;
  border-radius: 0;
}

select:focus,
textarea:focus,
input:focus {
  outline: none;
  background-color: #fff;
  color: #000;
  border-color: #FBD232;
}

select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #999;
}

select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: #999;
}

select:-moz-placeholder,
textarea:-moz-placeholder,
input:-moz-placeholder {
  color: #999;
}

select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: #999;
}

textarea {
  height: 200px;
  resize: vertical;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: #000;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: #000;
}

input[type="radio"], input[type="checkbox"] {
  width: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  padding: 15px 20px;
  width: auto;
  border: none;
  color: #111;
  text-transform: uppercase;
  letter-spacing: .05em;
  font-size: 13px;
  cursor: pointer;
}

.search-box {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
}

.search-form {
  position: absolute;
  top: 120%;
  left: 50%;
  margin-left: -120px;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-form .search-submit {
  display: none;
}

.search-form .search-field {
  width: 240px;
  height: 40px;
  box-sizing: border-box;
}

.searchbox-open {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 48em) {
  .search-form {
    right: 50px;
    left: auto;
    margin-left: 0;
    text-align: left;
    top: 0px;
  }
  .page-home01 .search-form {
    top: -8px;
  }
}

/*--------------------------------------------------------------
06. COMPONENTS
--------------------------------------------------------------*/
/*-----------------------------
06-01. Features
-----------------------------*/
.features-style-1 {
  padding-top: 70px;
  padding-bottom: 35px;
}

.features-style-1 .feature-item_title {
  font-size: 18px;
  color: #111;
  text-transform: capitalize;
}

.features-style-1 .description {
  color: #666;
  margin-bottom: 30px;
}

.services .features-style-1 .description {
  margin-bottom: 0;
}

.features-style-1 .feature-item {
  position: relative;
}

.features-style-1 .feature-item:before {
  content: "";
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 35px;
  z-index: 1;
}

.features-style-1 .feature-item-wrapper {
  position: relative;
  border: 2px solid #FBD232;
  padding: 30px 90px 25px 20px;
  margin-bottom: 30px;
  overflow: hidden;
}

.features-style-1 .feature-item-wrapper:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 434px 150px 434px 0;
  border-color: transparent;
  border-right-color: #FBD232;
  position: absolute;
  right: -60px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.features-style-1 .feature-item-wrapper p {
  font-size: 15px;
  color: #777;
}

.features-style-1 .feature-item-wrapper i {
  font-size: 50px;
  color: #111;
  margin-top: -25px;
  position: absolute;
  right: 10px;
  top: 50%;
}

.feature-style-2 {
  padding: 60px 0px 30px;
  position: relative;
  overflow: hidden;
}

.feature-style-2:before {
  content: '';
  display: block !important;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(17, 17, 17, 0.85);
  z-index: 0;
}

.feature-style-2 .feature-item {
  text-align: center;
  margin-bottom: 30px;
}

.feature-style-2 .feature-item_icon {
  font-size: 80px;
  color: #FBD232;
}

.feature-style-2 .feature-item_title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
}

.feature-style-2 .feature-item_description {
  color: #aaa;
  padding: 0px 15px;
}

@media (min-width: 64em) {
  .feature-item_price:before,
  .feature-item_clock:before {
    content: "";
    position: absolute;
    display: block;
    z-index: -2;
    top: -60px;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 169px 90px 169px 0;
  }
  .feature-item_clock:before {
    border-color: transparent rgba(17, 17, 17, 0.6) transparent transparent;
  }
  .feature-item_price:before {
    border-color: transparent rgba(17, 17, 17, 0.7) transparent transparent;
  }
  .feature-item_price:after,
  .feature-item_clock:after {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    top: -60px;
    left: 60px;
    height: 200%;
    width: 1200px;
  }
  .feature-item_price:after {
    background: rgba(17, 17, 17, 0.7);
  }
  .feature-item_clock:after {
    background: rgba(17, 17, 17, 0.6);
  }
}

/*-----------------------------
06-02. We are
-----------------------------*/
.we-are .heading-title {
  margin-bottom: 30px;
}

.we-are p {
  margin-bottom: 22px;
}

.we-are img {
  position: relative;
  z-index: 1;
}

.page-home03 .we-are .btn {
  margin-right: 10px;
  margin-bottom: 30px;
}

.page-home03 .we-are img {
  margin-top: 30px;
}

/* ---------- We-are-layout1 ----------- */
.we-are-layout1 {
  position: relative;
  overflow: hidden;
  padding: 40px 20px;
}

.we-are-layout1 .col-lg-6 {
  background: #fff;
  padding: 20px;
}

.we-are-layout1 li {
  vertical-align: top;
  padding: 0 12px;
  margin-bottom: 20px;
  border-left: 3px solid #FBD232;
  color: #777;
}

@media (min-width: 48em) {
  .we-are-layout1 .col-lg-6 {
    padding: 70px 20px;
  }
}

@media (min-width: 80em) {
  .we-are-layout1 {
    padding: 0;
  }
  .we-are-layout1:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: url("../images/page-home/home01_projects_bg-left.jpg") center left no-repeat;
    z-index: -2;
  }
  .we-are-layout1 .col-lg-6 {
    padding: 70px 0;
  }
  .we-are-layout1 .col-lg-6:before, .we-are-layout1 .col-lg-6:after {
    content: '';
    position: absolute;
    height: 50%;
    width: 160%;
    background: #fff;
    left: -130px;
    z-index: -1;
  }
  .we-are-layout1 .col-lg-6:before {
    top: 0;
    webkit-transform: skew(-30deg, 0);
    -moz-transform: skew(-30deg, 0);
    -ms-transform: skew(-30deg, 0);
    -o-transform: skew(-30deg, 0);
    transform: skew(-30deg, 0);
  }
  .we-are-layout1 .col-lg-6:after {
    bottom: 0;
    webkit-transform: skew(30deg, 0);
    -moz-transform: skew(30deg, 0);
    -ms-transform: skew(30deg, 0);
    -o-transform: skew(30deg, 0);
    transform: skew(30deg, 0);
  }
  .we-are-layout1 li {
    display: inline-block;
    width: 50%;
    margin-right: -3px;
    vertical-align: top;
    padding: 0 12px;
    margin-bottom: 20px;
    border-left: 3px solid #FBD232;
  }
}

/* ---------- We-are-layout2 ----------- */
.we-are-layout2_pro {
  padding: 80px 15px 30px;
  background: url("../images/page-home/home02_section1_bg.png") center center;
}

.we-are-layout2_call {
  background: #FBD232;
  max-width: 470px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.we-are-layout2_call h2 {
  font-size: 23px;
  border-bottom: 1px solid #e6c109;
  padding: 10px 0px 30px;
  margin-bottom: 30px;
}

.we-are-layout2_call .btn {
  background: #111;
  color: #FBD232;
}

@media (min-width: 80em) {
  .we-are-layout2_call {
    background: #FBD232;
  }
  .we-are-layout2_call img {
    position: relative;
    z-index: 99;
    margin-top: -40px;
  }
}

/*-----------------------------
06-03. Services
-----------------------------*/
/* --- .provide-services --- */
.provide-services {
  position: relative;
  margin-bottom: 30px;
}

.provide-services_img {
  margin-bottom: 35px;
}

.provide-services_title {
  font-size: 36px;
  line-height: 1.3;
}

.provide-services_description {
  color: #777;
  line-height: 1.8;
}

.provide-services:before {
  content: '';
  display: block !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  padding: 0 15px;
  background: #fff;
  background: -webkit-linear-gradient(#FFF, #F8F8F8);
  background: -o-linear-gradient(#FFF, #F8F8F8);
  background: -moz-linear-gradient(#FFF, #F8F8F8);
  background: linear-gradient(#FFF, #F8F8F8);
}

/* --- services --- */
.page-services-grid-01 .services,
.page-services-grid-03 .services {
  margin-bottom: 50px;
}

.page-services-grid-02 .services {
  margin-bottom: 20px;
}

.page-services-list .services {
  margin-bottom: 70px;
}

.page-home03 .services {
  padding: 70px 0px 50px;
}

.service-heading {
  background: #111;
  padding: 70px 0px;
  margin-bottom: 30px;
}

.service-heading p {
  color: rgba(225, 225, 225, 0.5);
}

.service-item {
  position: relative;
  margin-bottom: 60px;
}

.service-item:hover .service-item_img img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.service-item_img {
  overflow: hidden;
}

.service-item_icon {
  position: relative;
  font-size: 32px;
  width: 50px;
  height: 77px;
  line-height: 77px;
  text-align: center;
  display: inline-block;
  background: #111;
  color: #fff;
  z-index: 999;
}

.service-item_icon:before, .service-item_icon:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.service-item_icon:before {
  border-width: 38.5px 20px 38.5px 0px;
  border-right-color: #111;
  right: 100%;
}

.service-item_icon:after {
  border-width: 38.5px 0px 38.5px 20px;
  border-left-color: #111;
  left: 50px;
}

.service-item_link {
  font-family: Montserrat;
  font-size: 16px;
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
}

.service-item_link:hover {
  color: #111;
}

/* --- Grid 01 --- */
.services-style-01 .service-item:hover .service-item_icon {
  color: #111;
  background: #eee;
}

.services-style-01 .service-item:hover .service-item_icon:before {
  border-right-color: #eee;
}

.services-style-01 .service-item:hover .service-item_icon:after {
  border-left-color: #eee;
}

.services-style-01 .service-item_icon {
  position: absolute;
  left: 0;
  bottom: 0;
}

.services-style-01 .service-item_link {
  position: absolute;
  bottom: -38px;
  right: 0px;
  width: 80%;
  background: #FBD232;
  padding: 16px 20px;
  text-align: right;
}

/* --- Grid 02 --- */
.services-style-02 .service-item_icon {
  position: absolute;
  top: -22px;
  left: 0;
  color: #111;
  background: #FBD232;
}

.services-style-02 .service-item_icon:before {
  border-right-color: #FBD232;
}

.services-style-02 .service-item_icon:after {
  border-left-color: #FBD232;
}

.services-style-02 .service-item_link {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: #FBD232;
  padding: 16px 20px;
  text-align: right;
}

/* --- Grid 03 --- */
.services-style-03 .service-item_icon {
  margin-left: 20px;
  margin-bottom: 10px;
}

.services-style-03 .service-item_link {
  text-transform: uppercase;
  font-size: 18px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.services-style-03 .service-item_description {
  color: #777;
}

.services-style-03 .service-item:hover .service-item_icon {
  color: #111;
  background: #FBD232;
}

.services-style-03 .service-item:hover .service-item_icon:before {
  border-right-color: #FBD232;
}

.services-style-03 .service-item:hover .service-item_icon:after {
  border-left-color: #FBD232;
}

/* --- List --- */
.service-list_item {
  border: 2px solid #eee;
  border-bottom: none;
  padding: 30px 15px;
}

.service-list_item:last-child {
  border-bottom: 2px solid #eee;
}

.service-list_item-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.service-list_item-img:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0;
  background-color: #1d2736;
  opacity: 0.8;
  z-index: 1;
}

.service-list_item-img-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.service-list_item-img .service-item_icon {
  color: #111;
  background: #FBD232;
  margin-bottom: 20px;
}

.service-list_item-img .service-item_icon:before {
  border-right-color: #FBD232;
}

.service-list_item-img .service-item_icon:after {
  border-left-color: #FBD232;
}

.service-list_item-img .service-item_link {
  font-size: 24px;
  color: #fff;
}

.service-list_item-description_title {
  font-size: 28px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.service-list_item-description_content {
  color: #777;
}

.page-services-list .service-list_item-categories {
  padding-top: 60px;
}

@media (max-width: 47.9em) {
  .page-services-list .service-list_item-categories {
    padding-top: 30px;
  }
}

.service-list_item-categories li {
  line-height: 2.1;
}

.service-list_item-categories li:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: '\f0ad';
  font-size: 12px;
  display: inline-block;
  margin-right: 10px;
  color: #FBD232;
}

/* --- Service-detail --- */
.service-detail_img img {
  margin-bottom: 35px;
}

.service-detail_content {
  margin-bottom: 50px;
}

.service-detail_content .heading-title {
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.service-detail_content p {
  color: #777;
  line-height: 1.7;
}

.service-detail_content .service-categories_title {
  font-size: 18px;
  margin: 25px 0 15px;
}

.service-detail_content .service-list_item-categories {
  margin-bottom: 35px;
}

#accordion-service {
  margin-bottom: 35px;
}

#accordion-service .panel-title {
  position: relative;
}

#accordion-service .panel-title a {
  padding-left: 40px;
}

#accordion-service .panel-title a:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\f0ad";
  font-size: 12px;
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 9999;
  color: #111;
}

#accordion-service .panel-title a.collapsed:before {
  color: #FBD232;
}

#accordion-service .panel-body {
  padding-left: 20px;
  padding-right: 20px;
}

#collapse-service-one .panel-body {
  padding: 0;
}

.table-service {
  width: 100%;
}

.table-service th, .table-service td {
  padding: 13px 40px;
  width: 50%;
}

.table-service th {
  background: #eee;
  color: #111;
  font-weight: 700;
  font-size: 17px;
}

.table-service td {
  color: #777;
}

.table-service tr {
  border-bottom: 1px solid #eee;
}

.table-service tr:last-child {
  border-bottom: none;
}

.page-service-detail-02 .service-detail_content {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.page-service-detail-02 .service-detail_content p {
  margin-bottom: 15px;
}

.page-service-detail-02 .service-list_item-categories li {
  width: 50%;
  float: left;
}

.page-service-detail-02 .service-list_item-categories a {
  color: #777;
}

.service-detail_heading .heading-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.service-detail_heading p {
  color: #777;
  margin-bottom: 35px;
}

.service-price {
  text-align: center;
  margin-bottom: 40px;
}

.service-price_content {
  border: 2px solid #eee;
  border-top: none;
  border-bottom: none;
}

.service-price_heading {
  color: #fff;
  background: #FBD232;
  font-size: 18px;
  padding: 20px 0px;
  margin-bottom: 0;
}

.service-price_price {
  font-size: 48px;
  color: #111;
  font-weight: 600;
  background: #f7f7f7;
  padding: 30px 0px;
}

.service-price_price strong {
  vertical-align: top;
  font-size: 28px;
  margin-left: 5px;
}

.service-price_text {
  padding: 0px 20px;
  color: #777;
}

.service-price_text li {
  padding: 25px 0;
  border-bottom: 1px solid #eee;
}

.service-price_btn {
  padding: 20px 0px;
  margin-bottom: 30px;
  color: #111;
  background: #FBD232;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: Montserrat;
}

.service-price_btn:hover {
  color: #fff;
  background: #111;
}

.service-price_btn i {
  margin-left: 5px;
}

.page-service-detail-03 .table-service {
  border: 1px solid #eee;
  margin-bottom: 30px;
}

.page-service-detail-03 .table-service th {
  background: #FBD232;
}

/*-----------------------------
06-04. Our works
-----------------------------*/
.our-works {
  padding-top: 50px;
}

.our-works p {
  color: #666;
  margin-bottom: 30px;
}

.filters-button-group .button:hover, .filters-button-group .button.is-checked {
  background: #fff;
}

.filters-button-group .button:hover::before, .filters-button-group .button.is-checked::before {
  border-left-color: #FBD232;
}

.filters-button-group .button:hover::after, .filters-button-group .button.is-checked::after {
  border-left-color: #fff;
}

.filters-button-group .button {
  position: relative;
  color: #111;
  background: #FBD232;
  float: left;
  margin: 0;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  padding: 5px 20px 5px 35px;
  line-height: 38px;
}

.filters-button-group .button:focus {
  outline: none;
}

.filters-button-group .button:before, .filters-button-group .button:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  z-index: 1;
  top: 0;
  border-left-color: #FBD232;
}

.filters-button-group .button:before {
  border-width: 24px 0 24px 16.5px;
  right: -16px;
  border-left-color: rgba(255, 255, 255, 0.2);
}

.filters-button-group .button:after {
  border-width: 24px 0 24px 15px;
  right: -14px;
}

/* ---- ISOTOPE ---- */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

.element-item {
  position: relative;
  overflow: hidden;
  float: left;
  width: 100%;
  height: auto;
}

@media (min-width: 48em) {
  .element-item {
    width: calc(100%/2);
  }
}

@media (min-width: 64em) {
  .element-item {
    width: calc(100%/3);
  }
}

@media (min-width: 80em) {
  .element-item {
    width: calc(100%/5);
  }
}

.grid-overlay {
  position: absolute;
  top: -100%;
  background: rgba(17, 17, 17, 0.9);
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-overlay-category, .grid-overlay-tittle {
  display: block;
}

.grid-overlay-category {
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  color: #bbb;
}

.grid-overlay-category:hover {
  color: #bbb;
}

.grid-overlay-tittle {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: #FBD232;
}

.grid-overlay-tittle:hover {
  color: #FBD232;
}

.grid-overlay-icon {
  display: inline-block;
  color: #111;
  font-size: 17px;
  line-height: 44px;
  height: 44px;
  width: 30px;
  margin-top: 20px;
  background: #FBD232;
  position: relative;
}

.grid-overlay-icon:hover {
  color: #111;
}

.grid-overlay-icon:before, .grid-overlay-icon:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  position: absolute;
  top: 0;
}

.grid-overlay-icon:before {
  border-width: 22px 12.5px 22px 0;
  left: -12px;
  border-right-color: #FBD232;
}

.grid-overlay-icon:after {
  border-width: 22px 0px 22px 12.5px;
  right: -12px;
  border-left-color: #FBD232;
}

/*-----------------------------
06-05. Testimonial
-----------------------------*/
.owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-dot {
  display: inline-block;
  width: 30px;
  height: 5px;
  background: #ddd;
  margin: 2px;
}

.owl-dot.active, .owl-dot:hover {
  background: #FBD232;
}

.page-home01 .testimonial {
  padding: 60px 0px 70px;
}

.page-home01 .testimonial::after {
  content: "";
  display: table;
  clear: both;
}

.testimonial img {
  width: 70px;
  height: auto;
}

.testimonial .content-element {
  margin-bottom: 40px;
}

.testimonial blockquote {
  border: 2px solid #FBD232;
  padding: 30px 40px 36px 90px;
  margin-bottom: 30px;
  position: relative;
}

.testimonial blockquote p {
  font-size: 18px;
  font-style: italic;
  line-height: 1.6;
  color: #777;
}

.testimonial blockquote:before {
  font-family: FontAwesome;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  color: #FBD232;
  display: inline-block;
  content: "\f10d";
  position: absolute;
  top: 30px;
  left: 30px;
}

.testimonial blockquote:after {
  content: '';
  width: 20px;
  height: 2px;
  position: absolute;
  left: 30px;
  bottom: -2px;
  background: #fff;
}

.testimonial .avatar-link {
  float: left;
  margin-right: 20px;
}

.testimonial .author span {
  display: block;
  font-style: normal;
  text-transform: capitalize;
}

.testimonial .author-name {
  font-size: 18px;
  font-weight: 700;
  color: #111;
}

.testimonial .author-job {
  font-size: 15px;
  color: #999;
}

@media (min-width: 48em) {
  .testimonial .owl-dots {
    position: absolute;
    right: 0;
    bottom: 30px;
  }
}

.testimonial-2 {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.testimonial-2 .col-md-offset-6 {
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgba(17, 17, 17, 0.95);
}

@media (min-width: 48em) {
  .testimonial-2 .col-md-offset-6:before {
    content: '';
    width: 80px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    background: rgba(17, 17, 17, 0.95);
  }
  .testimonial-2 .col-md-offset-6:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 300px 0 300px 150px;
    border-left-color: rgba(17, 17, 17, 0.95);
    position: absolute;
    top: 50%;
    left: 100%;
    -ms-transform: translate(0, -50%);
    /* IE 9 */
    -webkit-transform: translate(0, -50%);
    /* Safari */
    transform: translate(0, -50%);
    /* Standard syntax */
  }
}

.testimonial-2 .heading-title {
  color: #fff;
  margin-bottom: 40px;
}

.testimonial-2 blockquote:after {
  background: #111;
}

.testimonial-2 blockquote p {
  color: #ccc;
}

.testimonial-2 .author-name {
  color: #fff;
}

/*-----------------------------
06-06. Contact form
-----------------------------*/
/* ------- Contact-form (Home-page-01) -------- */
.contact-form {
  overflow: hidden;
}

.contact-form .heading-title {
  color: #fff;
  font-size: 36px;
  margin-top: 70px;
}

.contact-form p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  margin-bottom: 30px;
}

.contact-form .col-md-offset-6 {
  background: rgba(17, 17, 17, 0.9);
}

.contact-form .col-md-offset-6:before {
  content: '';
  display: block;
  width: 80px;
  height: 100%;
  background: #111;
  position: absolute;
  top: 0;
  right: 100%;
  opacity: 0.9;
}

.contact-form .col-md-offset-6:after {
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 300px 0px 300px 150px;
  border-color: transparent;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 100%;
  border-left-color: #111;
  opacity: 0.9;
}

.contact-form form {
  margin-bottom: 70px;
}

.contact-form form .row {
  margin: 0 -5px;
}

.contact-form form [class*="col-"] {
  padding: 0px 5px;
  width: 100%;
}

.contact-form textarea {
  height: 110px;
}

.contact-form input, .contact-form select, .contact-form textarea {
  padding: 12px 15px;
  width: 100%;
}

.contact-form .btn {
  width: auto;
}

/* ---------- About us ----------- */
.contact-content {
  padding-bottom: 70px;
}

.thememove-gmaps {
  margin-bottom: 75px;
}

.contact-layout-02 .thememove-gmaps {
  margin-bottom: 20px;
}

.message {
  margin-bottom: 70px;
}

.message .heading-title {
  font-size: 36px;
  margin-bottom: 35px;
}

.call-us {
  margin-bottom: 70px;
  background: #111;
  color: #fff;
  padding: 50px 35px 15px;
}

.call-us_phone, .call-us_address {
  margin-bottom: 35px;
}

.contact-layout-02 .call-us_phone, .contact-layout-02 .call-us_address {
  padding-left: 10px;
}

.call-us h3 {
  margin-bottom: 40px;
  font-weight: 600;
  line-height: 1.2;
}

.call-us h4 {
  font-size: 20px;
}

.call-us_icon {
  color: #111;
  font-size: 18px;
  height: 43px;
  line-height: 42px;
  width: 30px;
  text-align: center;
  background: #FBD232;
  position: relative;
}

.call-us_icon:before, .call-us_icon:after {
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border: solid transparent;
}

.call-us_icon:before {
  left: -20px;
  border-width: 21.5px 10px 21.5px 10px;
  border-right-color: #FBD232;
}

.call-us_icon:after {
  right: -20px;
  border-width: 21.5px 10px 21.5px 10px;
  border-left-color: #FBD232;
}

.hotline {
  position: relative;
  max-width: 370px;
  margin-bottom: 35px;
}

.hotline:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #111;
  opacity: 0.9;
  z-index: 1;
}

.hotline-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  padding-left: 40px;
  z-index: 2;
}

.hotline-content h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.hotline-content p {
  font-size: 36px;
  color: #FBD232;
  font-weight: 700;
  font-family: Montserrat;
  margin-bottom: 0;
}

.hotline-description {
  margin-bottom: 35px;
  color: #777;
}

/*-----------------------------
06-07. Brands
-----------------------------*/
/* ----- Brand home01 ----- */
.brand {
  padding: 50px 0;
}

.brand-item {
  border: 2px solid #ddd;
  position: relative;
}

.brand-item:hover {
  border-color: #FBD232;
}

.brand-item:hover .brand-item-overlay {
  top: 0;
}

.brand-item:hover .brand-item-element {
  opacity: 1;
  font-size: 14px;
}

.brand-item-element {
  opacity: 0;
  font-size: 0;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.brand-item-overlay {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);
}

/* ----- Brand home02 ----- */
.page-home03 .brand-2 {
  padding: 50px 0 70px;
}

.brand-2 li {
  float: left;
  max-width: 180px;
  width: calc(100%/3 - 10px);
  padding: 5px;
  margin: 3px;
}

/* ----- testi-brand ----- */
.testi-brand {
  padding: 50px 0px;
}

.testi-brand .heading-title {
  margin: 20px 0px 40px;
}

.testi-brand blockquote {
  margin-top: 5px;
}

.testi-brand .testimonial, .testi-brand .brand-2 {
  margin-bottom: 30px;
}

/*-----------------------------
06-08. New tips
-----------------------------*/
.new-tips {
  background: url("../images/page-home/home02_new_bg.jpg") center center;
  padding-bottom: 20px;
  padding-top: 50px;
}

.new-tips_title h2 {
  margin-bottom: 10px;
}

.new-tips_title p {
  margin-bottom: 35px;
}

.new-tips .page-home02 {
  margin-top: 30px;
}

/*-----------------------------
06-09. Choose us
-----------------------------*/
.choose-us {
  background: #111;
  padding: 80px 0px 50px;
  margin-top: -29px;
}

.choose-us h2 {
  color: #fff;
  text-transform: capitalize;
  font-size: 36px;
  margin-bottom: 20px;
}

.choose-us h2 span {
  text-transform: uppercase;
  font-size: 22px;
  color: #FBD232;
  display: block;
}

.choose-us p {
  color: #777;
  line-height: 1.8;
}

.choose-us .hotline:before {
  background: #fff;
}

.choose-us .hotline h3 {
  color: #111;
}

.choose-us .hotline p {
  color: #FBD232;
}

.choose-us .service-list_item-categories a {
  color: #fff;
}

/*--------------------------------------------------------------
07. CONTENT
--------------------------------------------------------------*/
.site {
  overflow: hidden;
}

/*-----------------------------
07-01. Page extra
-----------------------------*/
/* ----------- PIECE OF US ----------- */
.piece-of-us {
  text-align: center;
  padding: 60px 0px;
  background: #eee;
}

.page-projects-single .piece-of-us {
  margin-top: 70px;
}

.piece-of-us_icon {
  font-size: 26px;
  color: #111;
  position: relative;
  width: 40px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  background: #FBD232;
}

.piece-of-us_icon::before, .piece-of-us_icon::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border: solid transparent;
}

.piece-of-us_icon::before {
  border-width: 30px 15px 30px 0;
  border-right-color: #FBD232;
  right: 100%;
}

.piece-of-us_icon::after {
  border-width: 30px 0px 30px 15px;
  border-left-color: #FBD232;
  left: 100%;
}

.piece-of-us h2 {
  color: #1d2736;
  font-size: 32px;
  text-transform: capitalize;
  line-height: 1;
  margin: 15px 0px;
}

.piece-of-us p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 15px;
}

.piece-of-us.piece-of-us_2 {
  background: #111;
}

.piece-of-us.piece-of-us_2 h2 {
  color: #fff;
}

.piece-of-us.piece-of-us_2 p {
  color: #777;
}

@media (min-width: 64em) {
  .piece-of-us {
    position: relative;
    overflow: hidden;
    padding: 0;
  }
  .piece-of-us h2, .piece-of-us p {
    text-align: left;
    margin-bottom: 5px;
  }
  .piece-of-us [class*="col"] {
    padding: 55px 0px;
  }
  .piece-of-us [class*="col"] * {
    position: relative;
    z-index: 6;
  }
  .piece-of-us .col-md-8:before, .piece-of-us .col-md-10:before {
    content: '';
    display: block;
    width: calc(100% - 90px);
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    background: #eee;
  }
  .piece-of-us .col-md-8:after, .piece-of-us .col-md-10:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 180px 0px 180px 100px;
    border-color: #fff;
    border-left-color: #eee;
    right: -10px;
    z-index: 4;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .piece-of-us:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 900px;
    height: 100%;
    background: #fff;
    z-index: 0;
  }
  .piece-of-us.piece-of-us_2:after {
    content: url(../images/page-home/home02_section6_bg.jpg);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }
  .piece-of-us.piece-of-us_2 .col-md-10:before {
    background: #111;
    z-index: 4;
  }
  .piece-of-us.piece-of-us_2 .col-md-10:after {
    border-color: transparent;
    border-left-color: #111;
    z-index: 5;
  }
}

/* ----------- PIECE OF US 2 ----------- */
/* ----------- POST SHARE BUTTONS ------------- */
.post-share-buttons {
  background: #f7f7f7;
  display: inline-block;
  margin: 0px 0px 30px;
}

.post-share-buttons span {
  position: relative;
  font-size: 13px;
  color: #111;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 15px;
  margin-right: 30px;
  background: #FBD232;
}

.post-share-buttons span:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 15px;
  border-color: transparent;
  position: absolute;
  right: -15px;
  top: 0;
  border-left-color: #FBD232;
}

.post-share-buttons i {
  font-size: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
}

/*-----------------------------
07-02. Page extra
-----------------------------*/
/* ---------- Project-fullwidth ----------- */
.page-projects-fullwidth .site-content {
  background: #111;
}

/* ---------- Project-basic, 3columns, sidebar ----------- */
.page-projects-basic .site-content,
.page-projects-3columns .site-content,
.page-projects-sidebar .site-content {
  background: #111;
}

.page-projects-basic .content,
.page-projects-3columns .content,
.page-projects-sidebar .content {
  background: #fff;
  padding: 50px 0px;
}

.page-projects-basic .content .col-xs-12,
.page-projects-3columns .content .col-xs-12,
.page-projects-sidebar .content .col-xs-12 {
  padding: 0;
}

.page-projects-basic .element-item,
.page-projects-3columns .element-item,
.page-projects-sidebar .element-item {
  width: 100%;
  padding: 15px;
}

.page-projects-basic .element-item .grid-overlay,
.page-projects-3columns .element-item .grid-overlay,
.page-projects-sidebar .element-item .grid-overlay {
  position: relative;
  text-align: left;
  display: block;
  background: #fff;
}

.page-projects-basic .grid-overlay-tittle,
.page-projects-3columns .grid-overlay-tittle,
.page-projects-sidebar .grid-overlay-tittle {
  color: #111;
  font-size: 20px;
  margin: 20px 0px 15px;
}

.page-projects-basic .grid-overlay-description,
.page-projects-3columns .grid-overlay-description,
.page-projects-sidebar .grid-overlay-description {
  color: #888;
  font-size: 15px;
  line-height: 27px;
  margin-bottom: 20px;
}

.page-projects-basic .grid-overlay-readmore,
.page-projects-3columns .grid-overlay-readmore,
.page-projects-sidebar .grid-overlay-readmore {
  color: #FBD232;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 48em) {
  .page-projects-basic .element-item,
  .page-projects-3columns .element-item,
  .page-projects-sidebar .element-item {
    width: calc(100%/2);
  }
}

@media (min-width: 64em) {
  .page-projects-basic .element-item,
  .page-projects-3columns .element-item {
    width: calc(100%/3);
  }
}

/* ---------- Projects Single ---------- */
.page-projects-single .single-image {
  margin-bottom: 20px;
}

.project-title {
  font-size: 32px;
  line-height: 38px;
  color: #111;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.project-description_title {
  font-size: 24px;
  line-height: 29px;
  color: #111;
  margin-bottom: 20px;
}

.project-description_content {
  color: #777;
  margin-bottom: 35px;
}

.project-details {
  margin-bottom: 40px;
}

.project-details table {
  border: 2px solid #eee;
  width: 100%;
}

.project-details table td {
  font-size: 15px;
  border-bottom: 2px solid #eee;
  padding: 25px 20px;
}

.project-details table .meta-title {
  color: #FBD232;
  font-weight: 700;
  display: block;
}

.project-details table .meta-value {
  color: #777;
}

.project-details table .meta-value a {
  color: #777;
}

/*-----------------------------
07-03. Blog
-----------------------------*/
.post .entry-meta {
  padding: 6px 0px 6px 15px;
  background: #FBD232;
  display: inline-block;
  width: 100%;
}

.post .entry-meta span {
  margin-right: 40px;
}

.post .entry-meta span:last-child {
  margin-right: 20px;
}

.post .entry-meta span a {
  color: #111;
}

.post .entry-title a {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #111;
}

.post .entry-title a:hover {
  color: #FBD232;
}

.post .entry-content {
  color: #777;
}

.post blockquote {
  border-left-width: 10px;
}

.post .read-more {
  color: #FBD232;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

.post .read-more i {
  margin-left: 5px;
}

.post-list-item,
.post-full-item {
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.post-grid-layout,
.post-list-item,
.post-full-item {
  border-bottom: 1px solid #eee;
}

.post-grid-item,
.post-full-item {
  padding-bottom: 20px;
}

.post-grid-item .entry-meta,
.post-full-item .entry-meta {
  margin-bottom: 20px;
}

.post-grid-item .entry-title,
.post-full-item .entry-title {
  margin-bottom: 20px;
}

.post-grid-item .entry-title a,
.post-full-item .entry-title a {
  font-size: 24px;
}

.post-grid-item,
.post-simple-item {
  margin-bottom: 30px;
}

.post-grid-item .entry-title,
.post-simple-item .entry-title {
  line-height: 1;
}

.post-grid-item .entry-title a,
.post-simple-item .entry-title a {
  font-size: 15px;
}

.post-list-item .entry-meta {
  margin-bottom: 5px;
}

.post-list-item .entry-title {
  margin-bottom: 10px;
}

@media (min-width: 64em) {
  .post .entry-meta {
    width: auto;
  }
  .post .entry-meta:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 17px 0px 17px 10px;
    position: absolute;
    left: 100%;
    top: 0;
    border-left-color: #FBD232;
  }
  .post blockquote {
    margin: 24px 40px;
  }
  .post-list-item .entry-meta {
    position: relative;
  }
  .post-grid-item,
  .post-simple-item,
  .post-full-item {
    padding-bottom: 20px;
  }
  .post-grid-item .post-thumb,
  .post-simple-item .post-thumb,
  .post-full-item .post-thumb {
    position: relative;
    margin-bottom: 35px;
  }
  .post-grid-item .post-thumb .entry-meta,
  .post-simple-item .post-thumb .entry-meta,
  .post-full-item .post-thumb .entry-meta {
    margin-bottom: 0;
    position: absolute;
    left: 0;
    bottom: -17px;
  }
}

/*-----------------------------
07-04. Shop
-----------------------------*/
/* ------- Fillter - in Page-shop ------- */
.products-result-count {
  color: #777;
}

.products-ordering {
  margin-bottom: 30px;
}

.products-ordering label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-right: 10px;
  margin-bottom: 0;
}

.products-ordering select {
  width: auto;
  vertical-align: middle;
  padding: 5px 10px;
  border: 2px solid #ddd;
  margin-bottom: 5px;
}

@media (min-width: 48em) {
  .products-result-count {
    margin-bottom: 30px;
  }
}

/* ------- Grid Products ------- */
.star-rating {
  position: relative;
  width: 5em;
  height: 15px;
  overflow: hidden;
  line-height: 1;
  margin: 5px 0px;
}

.products.grid-layout .star-rating {
  margin: 5px auto;
}

.star-rating:before {
  content: "\f005\f005\f005\f005";
  color: #FBD232;
  top: 0;
  left: 0;
  position: absolute;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.star-rating span:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: '\f005\f005\f005\f005\f005';
  color: #eee;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.amount {
  color: #777;
  font-weight: 700;
}

del .amount {
  margin-right: 5px;
  font-size: 13px;
  font-weight: 400;
}

ins .amount {
  color: #d90000;
}

.product {
  text-align: center;
  margin-bottom: 50px;
}

.product h3 {
  margin: 15px 0px 5px;
}

.product-thumb {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
}

.product-thumb img {
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 20px;
}

.product-title {
  font-size: 15px;
  text-transform: capitalize;
}

.onsale {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 20px;
  left: 15px;
  color: #fff;
  background-color: #d90000;
  border-radius: 100%;
  z-index: 0;
}

@media (min-width: 64em) {
  .product-thumb img {
    margin-bottom: 0;
  }
  .cart-button {
    position: absolute;
    width: 130px;
    padding: 12px 17px;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
  .product-thumb_link:before {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }
  .product:hover .product-thumb_link:before {
    opacity: 0.8;
    visibility: visible;
  }
  .product:hover .cart-button {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}

/* --------- Single Product ------------ */
.images-product {
  text-align: center;
  position: relative;
}

.images-product img {
  border: 1px solid #eee;
  padding: 15px;
}

.images-product .img-thumbnail {
  width: 30.75%;
  margin: 20px 10px;
}

.page-single-product .col-sm-6 {
  margin-bottom: 30px;
}

.page-single-product .onsale {
  left: 30px;
  z-index: 2;
}

.page-single-product .product-title {
  font-size: 36px;
  line-height: 1.2;
}

.page-single-product .price {
  font-size: 18px;
  margin-bottom: 10px;
}

.page-single-product .price del .amount {
  font-size: 15px;
}

.page-single-product .star-rating {
  margin-bottom: 15px;
}

.page-single-product .description {
  color: #777;
  margin-bottom: 20px;
}

.page-single-product .cart {
  margin-bottom: 20px;
}

.page-single-product .input-quantity {
  width: 100px;
  padding: 11px;
  border-width: 2px;
  text-align: center;
  color: #111;
  font-weight: 700;
  margin-right: 10px;
}

.page-single-product .product-meta {
  border-top: 1px solid #eee;
}

.page-single-product .product-share-buttons {
  margin-bottom: 40px;
}

.page-single-product .product-meta span, .page-single-product .product-share-buttons span {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  color: #777;
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
}

.page-single-product .product-meta a, .page-single-product .product-share-buttons a {
  text-transform: capitalize;
  font-weight: 400;
}

.reviews .control-label {
  display: block;
}

.reviews input, .reviews textarea {
  border-radius: 0;
  border-width: 2px;
}

.reviews input:focus, .reviews textarea:focus {
  border-color: #FBD232;
  box-shadow: none;
}

/* ---------- SHOPING CART ------------- */
.page-shop-cart .heading-title {
  font-size: 24px;
  margin-bottom: 35px;
}

.page-shop-cart .row-totals {
  margin-bottom: 40px;
}

.table-shopping-cart {
  margin-bottom: 120px;
}

.table-shopping-cart thead {
  background: #FBD232;
}

.table-shopping-cart thead > tr > th {
  border-bottom-color: #FBD232;
  padding: 10px;
  color: #fff;
}

.table-shopping-cart tbody > tr > td {
  vertical-align: middle;
}

.table-shopping-cart .product-remove i {
  cursor: pointer;
}

.table-shopping-cart .product-thumbnail img {
  width: 70px;
  min-width: 70px;
  border: 1px solid #eee;
  padding: 5px;
}

.table-shopping-cart .product-name a {
  font-weight: 700;
}

.table-shopping-cart .product-price, .table-shopping-cart .product-total {
  color: #d90000;
  font-weight: 700;
}

.table-shopping-cart .product-qty input {
  width: 100px;
  text-align: center;
  border: 2px solid #ddd;
}

.table-shopping-cart .product-qty input:focus {
  box-shadow: none;
  border-color: #FBD232;
}

.table-shopping-cart .actions {
  padding-top: 20px;
}

.table-cart-totals {
  width: 100%;
  border-top: 1px solid #eee;
}

.table-cart-totals tr {
  border-bottom: 1px solid #eee;
  line-height: 40px;
}

.table-cart-totals th {
  font-weight: 400;
  color: #777;
}

.table-cart-totals .amount {
  font-weight: 700;
  color: #d90000;
}

/* ------------- CHECKOUT --------------- */
.page-shop-checkout .btn {
  padding: 11px 17px;
}

.page-shop-checkout .required {
  color: red;
}

.page-shop-checkout .heading-title {
  font-size: 24px;
  margin-bottom: 40px;
}

.page-shop-checkout table {
  margin-bottom: 30px;
}

.checkout-login .btn {
  margin: 0px 10px 10px 0px;
}

.checkout-login p {
  color: #777;
}

.checkout-login p.lost_password a {
  color: #111;
  font-weight: 400;
}

.checkout-coupon {
  margin-bottom: 50px;
}

.checkout-login a, .checkout-coupon a {
  color: #FBD232;
  font-weight: 700;
}

.checkout-login form, .checkout-coupon form {
  border: 1px solid #d3ced2;
  padding: 20px;
}

.billing {
  margin-bottom: 50px;
}

.billing .checkbox {
  display: inline-block;
}

.billing .input-checkbox {
  margin: 15px 10px 0px 0px;
}

.ship-different {
  margin-bottom: 50px;
}

.ship-different .custom-heading h3 a:hover {
  color: #111;
}

.ship-different .custom-heading h3 input[type="checkbox"] {
  margin-top: 7px;
  margin-right: 10px;
}

.ship-different .custom-heading h3 label {
  margin-bottom: 0;
  cursor: pointer;
  display: inline;
}

.table-order-review {
  width: 100%;
}

.table-order-review th, .table-order-review td {
  width: 50%;
}

.table-order-review th {
  padding: 10px 20px;
  color: #fff;
  background: #FBD232;
}

.table-order-review td {
  padding: 20px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
}

.checkout-payment {
  margin-bottom: 40px;
}

.checkout-payment input[type="radio"], .checkout-payment input[type="checkbox"] {
  margin-top: 5px;
  margin-right: 15px;
}

.checkout-payment .payment_methods .parent {
  margin: 30px 0px;
  padding: 0px 15px;
}

.checkout-payment .payment_methods label {
  display: inline;
  text-transform: uppercase;
}

.checkout-payment .payment_methods label img {
  width: 150px;
  margin-left: 5px;
}

.checkout-payment .payment_methods .panel-collapse {
  margin-left: 30px;
}

.checkout-payment .place-order {
  padding: 15px;
  border-top: 1px solid #eee;
}

.checkout-payment .place-order label {
  display: inline-block;
  margin: 0;
}

.checkout-payment .place-order input[type="checkbox"] {
  margin: 13px 15px 0px 5px;
}

/* ------------- MY ACCOUNT --------------- */
.form-login {
  border: 1px solid #ddd;
  padding: 30px 20px;
  margin: 40px 0px 70px 0px;
}

/*-----------------------------
07-05. About
-----------------------------*/
/* ---- ABOUT 01 ---- */
.about-big-title {
  position: relative;
  padding: 200px 0px;
  margin-bottom: 60px;
  color: #fff;
  overflow: hidden;
}

.about-big-title h2 {
  font-size: 60px;
  margin-bottom: 40px;
}

.about-big-title p {
  font-size: 18px;
  text-transform: uppercase;
}

.about-slogan {
  border: 2px solid #FBD232;
  padding: 50px 0px 40px;
  margin-bottom: 50px;
}

.about-slogan h3 {
  margin-bottom: 25px;
}

.about-slogan p {
  color: #777;
}

.home-maintenance {
  margin-bottom: 35px;
}

.home-maintenance h3 {
  font-size: 36px;
  margin-bottom: 30px;
}

.home-maintenance p {
  color: #777;
  margin-bottom: 1.5em;
}

/* ---- ABOUT 02 ---- */
@media (max-width: 47.9em) {
  .about-view-projects img, .our-team_big-image img {
    margin-bottom: 20px;
  }
}

.about-view-projects h2, .our-team_big-image h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.about-view-projects h2 strong, .our-team_big-image h2 strong {
  color: #FBD232;
  display: block;
}

.about-view-projects p, .our-team_big-image p {
  color: #777;
  margin-bottom: 35px;
}

.about-view-projects .btn, .our-team_big-image .btn {
  margin-bottom: 20px;
}

/* ---- OUR TEAM ---- */
.page-our-team .our-team_style-1 {
  margin: 20px 0px 40px;
}

.page-our-team .our-team_style-2 {
  background: #f7f7f7;
  padding: 70px 0px 30px;
  margin: 0;
}

.our-team_big-image .img-border-bottom {
  margin-bottom: 60px;
  position: relative;
}

@media (min-width: 64em) {
  .our-team_big-image .img-border-bottom {
    padding-top: 110px;
  }
}

.our-team_big-image h2 {
  font-size: 36px;
}

.our-team_big-image img {
  margin-bottom: 0;
}

.our-team_big-image .heading-title {
  font-size: 24px;
  background: #FBD232;
  padding: 15px;
  margin-bottom: 60px;
}

@media (min-width: 64em) {
  .our-team_big-image .heading-title {
    padding: 30px;
    border-radius: 4px;
    position: absolute;
    top: 0px;
    right: 150px;
  }
  .our-team_big-image .heading-title:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 25px 0 0;
    border-color: transparent;
    position: absolute;
    left: 0;
    bottom: -20px;
    border-top-color: #FBD232;
  }
}

@media (min-width: 80em) {
  .our-team_big-image .heading-title {
    right: 240px;
  }
}

.img-border-bottom {
  border-bottom: 4px solid #FBD232;
  padding: 0px 15px;
  overflow: hidden;
}

.our-team {
  margin: 60px 0px;
}

.our-team .heading-title {
  font-size: 36px;
  margin-bottom: 10px;
}

.our-team_description {
  margin-bottom: 35px;
}

.our-team_item {
  margin-bottom: 30px;
}

.our-team_item-content-name {
  color: #FBD232;
}

.our-team_item strong {
  color: #111;
}

.our-team_item p {
  color: #888;
}

.our-team_item li {
  display: inline-block;
  margin: 0px 5px;
}

.our-team_item li a {
  color: #888;
}

.our-team_style-1 .our-team_item {
  margin-bottom: 30px;
}

.our-team_style-1 .our-team_item-img, .our-team_style-1 .our-team_item-content {
  display: table-cell;
}

.our-team_style-1 .our-team_item-img {
  width: 150px;
}

.our-team_style-1 .our-team_item-content {
  padding-left: 20px;
  vertical-align: top;
}

.our-team_style-2 .our-team_item {
  text-align: center;
}

.our-team_style-2 .our-team_item-img {
  margin-bottom: 20px;
}

/*-----------------------------
07-06. Page other
-----------------------------*/
/* ----------- 404 ------------- */
.not-found {
  padding: 100px 0px;
  background: #f7f7f7;
}

.not-found h2 {
  font-size: 60px;
  color: #FBD232;
}

.not-found h3 {
  font-size: 36px;
  text-transform: uppercase;
}

.not-found p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #777;
}

/* ----------- Site Maintenance ------------ */
.site-maintenance {
  background: url("/images/page-home/site_maintenance_page.jpg") no-repeat top left;
  display: table;
  width: 100%;
  height: 100vh;
}

.site-maintenance_content {
  display: table-cell;
  vertical-align: middle;
  padding: 30px 0px;
}

.site-maintenance h2 {
  font-size: 36px;
  text-transform: uppercase;
  color: #FBD232;
  margin-bottom: 10px;
}

.site-maintenance h3 {
  font-size: 58px;
  color: #fff;
  margin-bottom: 60px;
}

.site-maintenance ul {
  margin-bottom: 35px;
}

.site-maintenance li a {
  color: #fff;
  font-size: 24px;
}

.site-maintenance p {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 200px;
}

@media (max-width: 47.9em) {
  .site-maintenance h2 {
    font-size: 26px;
  }
  .site-maintenance h3 {
    font-size: 32px;
  }
  .site-maintenance p {
    margin-bottom: 30px;
  }
}

/* ----------- Page Title ------------ */
.page-title_content {
  margin-bottom: 50px;
}

.page-title_content p {
  color: #777;
  margin-bottom: 1.5em;
}

.quote-border-left {
  border-left-width: 10px;
  padding: 20px;
  margin: 24px 40px;
}

.page-title.image-bg .big-title,
.page-title.big-image-bg .big-title {
  position: relative;
  background: transparent;
}

.page-title.image-bg .big-title:after,
.page-title.big-image-bg .big-title:after {
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(17, 17, 17, 0.7);
  z-index: -1;
}

.page-title.big-image-bg .big-title {
  padding: 150px 0px;
  text-align: center;
}
