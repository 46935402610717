.menu {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  font-size: 0;
  a {
    display: block;
    font-size: 14px;
    &:hover {
      color: red;
    }
  }
}

.sub-menu {
  position: absolute;
  top: 120%;
  left: 0;
  z-index: 9999;
  padding: 0;
  margin-left: 0;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  @include box_shadow(3px 3px 3px 0 rgba(0, 0, 0, 0.2));
  @extend %transit;
  .sub-menu {
    left: 110%;
    top: 0;
  }
  li:hover > .sub-menu {
    left: 100%;
    top: -3px;
  }
  .menu-item {
    display: block;
  }
}

.menu-item {
  position: relative;
  display: inline-block;
  &:hover > .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 999999;
  }
}

/* ----- PRIMARY MENU ----- */
#primary-menu {
  position: relative;
  .menu {
    position: relative;
    a {
      text-transform: uppercase;
      font-weight: 600;
      font-family: Montserrat;
    }
    .menu-item-has-children {
      & > a:after {
        @include font-awesome;
        content: "\f105";
        font-size: 16px;
        position: absolute;
        right: 15px;
        top: 17px;
      }
    }
    .mega-menu {
      position: static;
      > ul {
        width: 100%;
        top: 100% !important;
        font-size: 15px;
      }
      .sub-menu > li:hover {
        background: transparent;
      }
    }
    .sub-menu {
      border-top: 3px solid $color-1;
      background: #fff;
      width: 300px;
      li {
        padding: 15px 20px 17px 20px;
        &:hover {
          background: $color-1;
          a {
            color: #111;
          }
        }
        a {
          text-transform: capitalize;
          font-weight: 400;
          font-family: Lato;
        }
      }

    }
  }
}

// Menu Style 01
.primary-menu_style-01 {
  .menu {
    a {
      color: #666666;
    }
    > li {
      border-bottom: 4px solid transparent;
      &:hover, &.active {
        border-color: $color-1;
        > a {
          color: #111;
        }
        > a:after {
          opacity: 1;
          visibility: visible;
          bottom: 0;
          margin-left: -8px;
          @extend %transit;
        }
        > .sub-menu {
          top: calc(100% + 4px);
        }
      }
      > a:after {
        @include triangle-rn();
        border-width: 0 8px 3px 8px;
        border-bottom-color: $color-1;
        position: absolute;
        left: 50%;
        top: auto;
        bottom: -10px;
        opacity: 0;
        visibility: hidden;
      }
    }
    li {
      padding: 27px 20px;
      margin-left: 0;
    }
  }
}

// Menu Style 02
.primary-menu_style-02 {
  background: $color-1;
  position: relative;
  margin-bottom: -36px;

  .menu {
    position: relative;
    z-index: 999;
    &:before {
      content: '';
      position: absolute;
      width: 1000px;
      height: 100%;
      top: 0;
      left: -1000px;
      background: $color-1;
    }
    &:after {
      @include triangle-rn();
      z-index: 3;
      border-left-color: $color-1;
      border-width: 35px 0 35px 20px;
      left: 0px;
    }
    > .menu-item {
      padding: 25px 10px 25px 40px;
      background: $color-1;

      &:before {
        @include triangle-rn();
        z-index: 4;
        border-left-color: $color-1;
        border-width: 35px 0 35px 20px;
        right: -20px;
      }
      &:after {
        @include triangle-rn();
        z-index: 3;
        border-left-color: rgba(255, 255, 255, 0.2);
        border-width: 35px 0 35px 20px;
        right: -21px;
      }

      &:hover {
        background: #111;
        > a {
          color: #fff;
          @extend %transit;
        }
        &:before {
          border-left-color: #111;
        }
        &:after {
          border-left-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .mega-menu {
      position: relative !important;
      .sub-menu {
        width: 1140px !important;
        max-width: 1140px;
        left: -95px;
      }
    }
  }
}

// Menu Style 03
.primary-menu_style-03 {
  .menu {
    > .menu-item {
      > a {
        padding: 54px 0px;
        position: relative;
        &:before {
          content: '';
          width: 0;
          height: 4px;
          background: $color-1;
          position: absolute;
          top: 0;
          left: 0;
          @extend %transit;
        }
      }
      &.menu-item-has-children {
        > a:after {
          content: '\f0d7' !important;
          right: -15px !important;
          top: calc(50% - 8px) !important;
          color: $color-1;
        }
      }
      &.active, &:hover {
        > a {
          color: #111;
        }
        > a:before {
          width: 100%;
        }
      }
    }
  }
}

//TOP RIGHT MENU
#top-right-menu {
  a {
    color: rgba(255, 255, 255, 0.6);
    &:hover {
      color: #fff;
    }
  }
  .sub-menu {
    li {
      display: block;
      padding: 5px 10px;
      margin: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
    .sub-menu {
      top: 0;
      left: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    .menu > .menu-item {
      padding: 9px 14px;
      border-left: 1px solid rgba(238, 238, 238, 0.1);
      &:last-child {
        border-right: 1px solid rgba(238, 238, 238, 0.1);
      }
    }
  }

  @include media-breakpoint-up(md) {
    .menu > .menu-item {
      padding: 9px 30px;
    }
  }
}

