/*------------------------------------------------------------------
Theme Name: Renovation
Version: 1.0
Author:
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[TABLE OF CONTENTS]

## 01. GLOBAL STYLES
   01-01. Normalize
   01-02. Typography
   01-03. List

## 02. COMBO CSS

## 03. NAVIGATION
   03-01. Menus
   03-01. Mobile menu
   03-01. Social menu

## 04. LAYOUT
   04-01. Header
   04-02. Footer
   04-03. Widget, sidebar
   04-04. Mini cart

## 05. OBJECTS
   05-01. Button
   05-02. Collapse
   05-03. Slider
   05-04. Breadcrumb
   05-05. Custom heading
   05-06. Pagination
   05-07. Scrollup
   05-08. Forms

## 06. COMPONENTS
   06-01. Features
   06-02. We are
   06-03. Services
   06-04. Our works
   06-05. Testimonial
   06-06. Contact form
   06-07. Brands
   06-08. New tips
   06-09. Choose us

## 07. CONTENT
   07-01. Page extra
   07-02. Page extra
   07-03. Blog
   07-04. Shop
   07-05. About
   07-06. Page other

-------------------------------------------------------------------*/

@import "utils/variables";
@import "utils/mixins";
@import "vendors/vendors";

/*--------------------------------------------------------------
01. GLOBAL STYLES
--------------------------------------------------------------*/
@import "base/_base";
/*--------------------------------------------------------------
02. COMBO CSS
--------------------------------------------------------------*/
@import "utils/placeholders";
/*--------------------------------------------------------------
03. NAVIGATION
--------------------------------------------------------------*/
@import "navigation/navigation";
/*--------------------------------------------------------------
04. LAYOUT
--------------------------------------------------------------*/
@import "layout/layout";
/*--------------------------------------------------------------
05. OBJECTS
--------------------------------------------------------------*/
@import "objects/objects";
/*--------------------------------------------------------------
06. COMPONENTS
--------------------------------------------------------------*/
@import "components/components";
/*--------------------------------------------------------------
07. CONTENT
--------------------------------------------------------------*/
@import "page/page";