select,
textarea,
input {
  display        : inline-block;
  margin-bottom  : 10px;
  padding        : 12px 15px;
  max-width      : 100%;
  width: 100%;
  border         : 2px solid #ddd;
  box-shadow     : none;
  color          : #888;
  vertical-align : top;
  font-size      : 14px;
  border-radius: 0;
  @extend %transit;
  &:focus {
    outline          : none;
    background-color : #fff;
    color            : #000;
    border-color: $color-1;
  }
  @include placeholder {
    color : #999;
  }
}

textarea {
  height : 200px;
  resize : vertical;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: #000;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: #000;
}

input[type="radio"],input[type="checkbox"] {
  width: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  padding          : 15px 20px;
  width            : auto;
  border           : none;
  color            : #111;
  text-transform   : uppercase;
  letter-spacing   : .05em;
  font-size        : 13px;
  cursor           : pointer;
  @extend %transit;
}

.search-box {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
}
//SEARCH FORM
.search-form {
  position: absolute;
  top: 120%;
  left: 50%;
  margin-left: -120px;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  @include transit;
  .search-submit {
    display: none;
  }
  .search-field {
    width: 240px;
    height: 40px;
    box-sizing: border-box;
  }
}

.searchbox-open {
  opacity: 1;
  visibility: visible;
}

///////////////sm//////////////////
@include media-breakpoint-up(sm) {
  .search-form {
    right: 50px;
    left: auto;
    margin-left: 0;
    text-align: left;
    top: 0px;
    .page-home01 & {
      top: -8px;
    }
  }
}
